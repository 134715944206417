import React from "react"
import Seo from "../components/seo"

import Logo from "../assets/svg/nalma-logo.svg"
import LogoAzul from "../assets/svg/nalma-logo-azul.svg"
import Slash from "../assets/svg/slash.svg"

import IG from "../assets/svg/ig_logo.svg"
import IN from "../assets/svg/in_logo.svg"


import "../styles/custom.scss"

const IndexPage = () => (
  <main>
    <Seo title="Nalma Estudio"/>
    <div className="hero">
      <Logo className="logo"/>
    </div>

    <div className="form-container">
      <header>
        <LogoAzul className="logo"/>
        <Slash className="slash"/>
      </header>
      <h1>HÁBLANOS DE TUS PROYECTOS</h1>

      <form name='Nalma Estudio | Contacto Sitio Web' method='post' action="/" data-netlify='true' data-netlify-honeypot='bot-field' autoComplete='off'>
        <input type='hidden' name='bot-field'/>
        <input type='hidden' name='form-name' value='Nalma Estudio | Contacto Sitio Web'/>

        <fieldset className="name-container">
          <label htmlFor="name">¿CUÁL ES TU NOMBRE?</label>
          <input type="text" name="name" required/>
        </fieldset>

        <fieldset className="text-container">
          <label htmlFor="subject">¿EN QUÉ PODEMOS AYUDARTE?</label>
          <textarea type="text" name="subject" required/>
        </fieldset>

        <fieldset className="mail-container">
          <label htmlFor="mail">DÉJANOS TU CORREO Y NOS PONEMOS EN CONTACTO :)</label>
          <input type="email" name="mail" required/>
        </fieldset>

        <button type="submit">ENVIAR</button>
      </form>

      <div className="sociales">
        <a href="https://instagram.com/nalmaestudio"><IG/></a>
        <a href="https://linkedin.com/in/nalmaestudio"><IN/></a>
      </div>

    </div>
  </main>
)

export default IndexPage
